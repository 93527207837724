import ThreeGlobe from "three-globe"
import {WebGLRenderer, Scene, PointsMaterial, Points, Float32BufferAttribute, BufferGeometry} from "three"
import {
    PerspectiveCamera,
    AmbientLight,
    DirectionalLight,
    Color,
    Fog,
    // AxesHelper,
    // DirectionalLightHelper,
    // CameraHelper,
    PointLight,
    SphereGeometry,
} from "three"
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls.js"
// import { createGlowMesh } from "three-glow-mesh";
import countries from "./files/globe-data-min.json"
// import travelHistory from "./files/my-flights.json";
import travelHistory from "./files/transports.json"
// import airportHistory from "./files/my-airports.json";
import airportHistory from "./files/locations.json"

const targetContainer = document.getElementById('the-world')
let renderer, camera, scene, controls;
let mouseX = 0;
let mouseY = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;
let Globe;

// particles
let particleSystem
let particleCount = 10 // Number of particles
let globeRadius = 120 // Assuming the globe has this radius
let particleGeometry = new BufferGeometry()
let positions = []
let offsets = []


// init();


// SECTION Initializing core ThreeJS elements
function init() {
    const {width, height} = targetContainer.getBoundingClientRect()
    // Initialize renderer
    renderer = new WebGLRenderer({antialias: true, alpha: true})
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height)
    // renderer.outputEncoding = sRGBEncoding
    targetContainer.appendChild(renderer.domElement)
    // document.body.appendChild(renderer.domElement)

    // Initialize scene, light
    scene = new Scene()
    scene.add(new AmbientLight(0xbbbbbb, 0.3))
    // scene.background = new Color(0x040d21);
    scene.background = null

    // Initialize camera, light
    camera = new PerspectiveCamera()
    camera.aspect = width / height
    camera.filmOffset = 20
    camera.updateProjectionMatrix()

    var dLight = new DirectionalLight(0xffffff, 0.8)
    dLight.position.set(-800, 2000, 400)
    camera.add(dLight)

    // var dLight1 = new DirectionalLight(0x7982f6, 1)
    // var dLight1 = new DirectionalLight(0xB22024, 1) secondary color
    var dLight1 = new DirectionalLight(0xB7BBD2, 1)
    dLight1.position.set(-200, 500, 200)
    camera.add(dLight1)

    // var dLight2 = new PointLight(0x8566cc, 0.5)
    // var dLight2 = new PointLight(0xB22024, 0.5) secondary color
    var dLight2 = new PointLight(0xB7BBD2, 0.5)
    dLight2.position.set(-200, 500, 200)
    camera.add(dLight2)

    camera.position.z = 300
    camera.position.x = 0
    camera.position.y = 0

    scene.add(camera)

    // Additional effects
    // scene.fog = new Fog(0x535ef3, 400, 2000)
    scene.fog = new Fog(0xB22024, 400, 2000) // secondary color

    // Helpers
    // const axesHelper = new AxesHelper(800);
    // scene.add(axesHelper);
    // var helper = new DirectionalLightHelper(dLight);
    // scene.add(helper);
    // var helperCamera = new CameraHelper(dLight.shadow.camera);
    // scene.add(helperCamera);

    // Initialize controls
    controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true
    controls.dynamicDampingFactor = 0.01
    controls.enablePan = false
    controls.minDistance = 200
    controls.maxDistance = 500
    // controls.zoomSpeed = 1;
    controls.enableZoom = false
    controls.autoRotate = true
    controls.rotateSpeed = 0.2
    controls.enablePan = false
    controls.enableRotate = false

    // controls.minPolarAngle = Math.PI / 3.5
    // controls.maxPolarAngle = Math.PI - Math.PI / 3

    window.addEventListener("resize", onWindowResize, false)
    document.addEventListener("mousemove", onMouseMove)

    initGlobe()
    initParticles()
    onWindowResize()
    animate()
}

// SECTION Globe
function initGlobe() {
    // Initialize the Globe
    Globe = new ThreeGlobe({
        waitForGlobeReady: true,
        animateIn: true,
    })
        .showAtmosphere(true)
        .atmosphereColor("#2A357A")
        .atmosphereAltitude(0.25)
        .hexPolygonsData(countries.features)
        .hexPolygonResolution(3)
        .hexPolygonMargin(0.4)
        .hexPolygonUseDots(true)
        .hexPolygonColor((e) => "#ffffff")
    // .hexPolygonColor((e) => {
    //     if (
    //         // ["DEU","KGZ", "KOR", "THA", "RUS", "UZB", "IDN", "KAZ", "MYS"].includes(
    //         ["DEU","GBR","IRL"].includes(
    //             e.properties.ISO_A3
    //         )
    //     ) {
    //         return "rgba(255,255,255, 1)";
    //     } else return "rgba(255,255,255, 0.7)"
    // })

    // NOTE Arc animations are followed after the globe enters the scene
    // setTimeout(() => {
        Globe.arcsData(travelHistory.flights)
            // .arcColor((e) => {
            //     return e.status ? "#B22024" : "#B22024"
            // })
            .arcColor((e) => "#B22024")
            .arcAltitude((e) => {
                return e.arcAlt
            })
            // .arcStroke((e) => {
            //     return e.status ? 0.5 : 0.3
            // })
            .arcStroke((e) => {
                return 0.4
            })
            .arcDashLength(0.9)
            .arcDashGap(4)
            .arcDashAnimateTime(1000)
            .arcsTransitionDuration(1000)
            .arcDashInitialGap((e) => e.order * 1)
            .labelsData(airportHistory.airports)
            .labelColor(() => "#ffffff")
            .labelDotOrientation((e) => {
                return e.text === "ALA" ? "top" : "right"
            })
            .labelDotRadius(0.3)
            .labelSize((e) => e.size)
            .labelText("city")
            .labelResolution(6)
            .labelAltitude(0.1)
            .pointsData(airportHistory.airports)
            .pointColor(() => "#ffffff")
            .pointsMerge(true)
            .pointAltitude(0.07)
            .pointRadius(0.05)
    // }, 200)
    // Globe.rotateY(-Math.PI * (5 / 20))
    Globe.rotateY(0.4)
    // Globe.rotateZ(-Math.PI / 6)
    Globe.rotateZ(0.7)
    const globeMaterial = Globe.globeMaterial()
    globeMaterial.color = new Color(0x2A357A)
    globeMaterial.emissive = new Color(0x220038)
    globeMaterial.emissiveIntensity = 0.1
    globeMaterial.shininess = 0.7

    // NOTE Cool stuff
    // globeMaterial.wireframe = true
    // globeMaterial.wireframeLinewidth = 2 // Thinner lines
    // globeMaterial.opacity = 0.5; // Make it semi-transparent if the material is transparent
    // globeMaterial.transparent = true

    scene.add(Globe)
}

function initParticles() {

    // for (let i = 0; i < particleCount; i++) {
    //     // Generate random positions within a sphere
    //     let theta = Math.random() * 2 * Math.PI // Random angle in radians
    //     let phi = Math.acos((Math.random() * 2) - 1) // Random angle in radians
    //     let radius = globeRadius + (Math.random() * 0.5) // Random radius from globe surface
    //
    //     // Spherical to Cartesian conversion
    //     let x = radius * Math.sin(phi) * Math.cos(theta)
    //     let y = radius * Math.sin(phi) * Math.sin(theta)
    //     let z = radius * Math.cos(phi)
    //
    //     positions.push(x, y, z)
    //     offsets.push(Math.random() * 2 * Math.PI)
    // }
    // particleGeometry.setAttribute('position', new Float32BufferAttribute(positions, 3))
    // particleGeometry.setAttribute('offset', new Float32BufferAttribute(offsets, 1))
    // let particleMaterial = new PointsMaterial({color: 0xffffff, size: 0.5, transparent: true, opacity: 0.9})
    // particleSystem = new Points(particleGeometry, particleMaterial)
    // scene.add(particleSystem)
}

function onMouseMove(event) {
    mouseX = event.clientX - windowHalfX
    mouseY = event.clientY - windowHalfY
    // console.log("x: " + mouseX + " y: " + mouseY);
}

function onWindowResize() {
    const {width, height} = targetContainer.getBoundingClientRect()
    const minWidth = 768 // Minimum viewport width to start scaling the offset
    const maxWidth = 1440 // Max viewport width to consider for scaling
    const maxOffset = 20 // Maximum offset value
    let offset = ((width - minWidth) / (maxWidth - minWidth)) * maxOffset;
    // Ensure offset is 0 if viewport width is below minWidth
    if (width < minWidth) {
        offset = 0
    } else {
        offset = Math.min(offset, maxOffset)
    }
    camera.aspect = width / height
    camera.filmOffset = offset
    camera.updateProjectionMatrix()
    windowHalfX = width / 1.5
    windowHalfY = height / 1.5
    renderer.setSize(width, height)
}

function animate() {
    // camera.position.x +=
    //     Math.abs(mouseX) <= windowHalfX / 2
    //         ? (mouseX / 2 - camera.position.x) * 0.005
    //         : 0
    // camera.position.y += (-mouseY / 2 - camera.position.y) * 0.005
    // camera.position.z = 300
    // camera.lookAt(scene.position)

    controls.update()
    renderer.render(scene, camera)
    requestAnimationFrame(animate)

    // animateParticles() // Convert time to seconds for smoother animation
}

// Function to update particle positions
// function animateParticles() {
//     let time = Date.now() * 0.001 // Current time in seconds
//
//     // Directly access the 'position' and 'offset' attributes
//     let positions = particleSystem.geometry.attributes.position.array
//     let offsets = particleSystem.geometry.attributes.offset.array // Ensure this exists
//
//     for (let i = 0; i < positions.length; i += 3) {
//         let offset = offsets[i / 3]; // Correctly access the corresponding offset
//         positions[i + 2] += Math.sin(time + offset) * 0.5 // Apply sine wave movement
//     }
//
//     particleSystem.geometry.attributes.position.needsUpdate = true
// }

document.addEventListener('DOMContentLoaded', (event) => {
    if (targetContainer) {
        init()
    }
})

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR - world")
    })
}